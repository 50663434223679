






























































import { Component, Vue } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { locale } from "@/locale/i18n";
import RouteUtil from "@/utilities/RouteUtil";
import FacebookService from "@/services/FacebookService";
import CompanyService from "@/services/CompanyService";
import store from "@/main-store";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: {
        AlertDialogView
    },
    data: () => ({
        title: ""
    })
})
export default class LoginView extends Vue {
    private alertDialog = new AlertDialogProperties();
    private facebookService = new FacebookService();
    private companyService = new CompanyService();

    public get languages() {
        return [
            { key: "en", text: "English" },
			{ key: "ms", text: "Malay" },
            { key: "zh", text: "中文" },
        ];
    }

    public get locale() {
        return this.$i18n.locale;
    }
    public set locale(v) {
        this.$cookies.set("lang", v, "9y");
        locale.changeLangauge(v);
    }

    public async created() {
        await this.loadCompany();

        if (this.$route.hash === "#not-logged-in") {
			const m = this.$t("message.not-logged-in");
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.tag = { command: "not-logged-in" };
            this.alertDialog.visible = true;
        }
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t('title.app').toString();
            this.$data.title = document.title;
        }
    }

    private async loginWithFacebook() {
        var redirectUrl = RouteUtil.getUrlWithoutPathname(true);
        redirectUrl += "authenticating?type=login";
        const r = await this.facebookService.loginUrl(redirectUrl, false);
        window.location.href = r.data;
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        if (this.$route.hash !== "") {
            await this.$router.replace("/");
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "not-logged-in") {
            await store.reloadUser();
        }
    }
}
